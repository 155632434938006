<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-show="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <label>Region</label>
          <v-select
            v-model="region"
            label="name"
            placeholder="Pilih Region"
            :options="optionRegion"
            @input="changeSelectRegion"
          />
        </b-col>
        <b-col cols="12">
          <label>Status</label>
          <v-select
            v-model="status"
            label="name"
            placeholder="Pilih Status"
            :options="optionStatus"
            @input="changeSelectStatus"
          />
        </b-col>
        <b-col cols="12">
          <label>Periode</label>
          <div class="row">
            <div class="col-md-6">
              <flat-pickr
                v-model="tanggalA"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
              />
            </div>
            <div class="col-md-1">
              <center><span class="mt-1">s/d</span></center>
            </div>
            <div class="col-md-5">
              <flat-pickr
                v-model="tanggalB"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
              />
            </div>
          </div>
        </b-col>
       
        <b-col
          cols="12"
          class="mt-2"
        >
          <b-button
            class="mr-1"
            variant="outline-secondary"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
          <b-button
            class="mr-1"
            variant="primary"
            type="button"
            @click.prevent="getFilter()"
          >Filter</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card v-if="renderComp">
      <basetable
        ref="basetable"
        :dataurl="dataurl+'?filter[spbs.vendor_id]='+user.id"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :custom_filter="custom_filter"
        :is-detail="isDetail"
        :tombol_hide="true"
        :is-add="true"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="float-right"
            variant="info"
            @click="showFilter =!showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            Filter
          </b-button>
        </template>
        <template #cell(number)="data">
          {{ data.item.number }}
          <br>
          <small class="text-muted">{{ data.item.date }}</small>
        </template>
        <template #cell(status)="data">
       <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'created'">Pembuatan SPB</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'prepared'">Penyiapan Unit Oleh Vendor</span></center>
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'applied'">Pengajuan SPB</span></center>
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'delivered'">Pengiriman SPB Ke Vendor</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'viewed'">SPB Ditinjau</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'approved'">SPB Ditinjau</span></center>
        <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'canceled'">Dibatalkan</span></center>
        <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'returned'">SPB Direvisi</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'received'">Pengiriman Unit</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'distributed'">Pengiriman Unit dari Vendor</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'request_rikmatek'">Pengajuan Rikmatek</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'checked'">Sudah Diperiksa</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'send_letter_distribute'">Sudah Pemeriksaan</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_published'">BAPP Dipublish</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_requested'">BAPP Direquest</span></center>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard,BButton,BRow,BCol, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    vSelect,
    BRow,
    BCol,
    flatPickr
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/spb",
      baseroute:"spb-vendor",
      title:"Daftar SPB",
      fields: [
        { key: 'region.name', label: 'Region', sortable: true },
        { key: 'number', label: 'No SPB', sortable: true },
        { key: 'contract_subject', label: 'Judul Kontrak', sortable: true },
        { key: 'contract_number', label: 'No Kontrak', sortable: true },
        { key: 'vendor.name', label: 'Vendor'},
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      custom_filter : true,
      isDetail : true,
      tanggalA:null,
      tanggalB:null,
      renderComp : true,
      isDelete : false,
      isEdit : false,
      optionRegion :[],
      region:[],
      status:[],
      filter:[],
      optionStatus:[
        {id:'canceled',name:'Dibatalkan'},
        {id:'viewed',name:'SPB Ditinjau'},
        {id:'created',name:'Pembuatan SPB'},
        {id:'applied',name:'Pengajuan SPB'},
        {id:'delivered',name:'Pengiriman SPB Ke Vendor'},
        {id:'prepared',name:'Penyiapan Unit Oleh Vendor'},
        {id:'approved',name:'SPB Disetujui'},
        {id:'returned',name:'SPB Direvisi'},
      ]
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },
  mounted(){
    this.getRegion()
  },
  methods:{
    getRegion(){
      this.$http.get('region').then(res=>{
        this.optionRegion = res.data.data
      })
    },
    show(){
      this.$refs.basetable.fetchData()
    },
    changeSelectRegion(data){
      if(data != null){
        this.filter['filter[branch_id]'] = data.id
        this.region.id = data.id
        this.region.name = data.name
      }
    },
    changeSelectStatus(data){
      if(data != null){
        this.filter.status = data.id
      }
    },
    getFilter(){
      this.filter['between[date]'] = this.tanggalA+','+this.tanggalB
      this.renderComp = false
      this.$nextTick().then(() => {
        this.renderComp = true
      })
    },
    reset(){
      this.region = []
      this.status = []
      this.filter=[]
      this.renderComp = false
      this.$nextTick().then(() => {
        this.renderComp = true
      })
    }
  }
}
</script>
<style lang="">

</style>
